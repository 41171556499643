import "./src/styles/global.css";

import * as React from "react";
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews";

const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    componentResolver: {
      page: React.lazy(() => import("./src/templates/page.tsx")),
    },
  },
];

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
    {element}
  </PrismicPreviewProvider>
);
