module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"partneralert","accessToken":"MC5aUzBWY3hBQUFDUUF6VEpL.77-977-9RFFzbGB6CO-_ve-_ve-_ve-_vWvvv71w77-977-9D--_vSZu77-9f--_ve-_ve-_vXIx77-977-9ag"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
